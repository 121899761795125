<template>
    <div>
        <el-tree
            ref="tree"
            :key="'tree_'+currentCate"
            :size="$size"
            icon-class="el-icon-arrow-right1"
            :data="treeView"
            :props="defaultProps"
            :expand-on-click-node="false"
            node-key="id"
            default-expand-all
            highlight-current
            indent="8"
            :current-node-key="currentCate"
            @node-click="handleNodeClick"
             >
            <div slot-scope="{ node, data }" >
                <div v-if=" data.root==1"  >
                    
                    <div   data-level="1"style="font-weight:bold;font-size: 14px;">{{ node.label }} </div>
                </div>
                <div v-else-if="data.children">
                    
                    <div v-if="data.child && data.root!=1" data-level="3" style="font-weight:bold;">{{ node.label }} </div>
                    <div v-else data-level="2" style="font-weight:bold;font-size: 14px;">{{ node.label }} </div>
                </div>
                
                <div v-else data-level="4" style="white-space: normal;line-height:16px;margin: 2px 0;">{{ node.label }} </div>
            </div>
        </el-tree>
      
    </div>
</template>

<script>
    export default {
        name:"SiderComp",
        props: {
            link: {
                type: Boolean,
                default: true
            },
            
        },
        data() {


            return {
                tree: [
                    {
                    id: 1,
                    root:1,
                    label: 'XINSHANG BRAND',
                    children: [
                  
                        ]
                    },   
                ],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                 
            }
        },
        computed: {
            currentCate(){
                return parseInt( this.$store.state.currentCate);
            },
            treeView() {
                let tree = this.tree;
                let root=tree[0];
                root.children=[];

                let brands=this.$store.state.brands;
                let cate=this.$store.state.categories; 
                let user=this.$store.state.user;
                if (user && user.brands){
                    let _brand=user.brands.split(",");
                    brands=brands.filter(x=>_brand.indexOf(x.id.toString())>=0);
                    console.log(brands)
                }


               
                for (let index = 0; index < brands.length; index++) {
                    const brand = brands[index];
                    if (brand.id==1035){continue;}
                    let node={id:brand.id,label:brand.name,children:[]};

                    /**
                     * cate转为树数据
                     */
                    var swap=function(cate,topBrandId){
                        
                        for (let index = 0; index < cate.length; index++) {
                            cate[index].label=cate[index].name;
                            cate[index].iscate=true;
                           
                            if (topBrandId!=0 && cate[index].brandId==0){
                                cate[index].brandId=topBrandId;
                            }
                           
                            //console.log(cate[index].label,cate[index].brandId)

                            cate[index].children=cate[index].child
                            if (cate[index].child){
                                swap(cate[index].child,cate[index].brandId==topBrandId?topBrandId:0);
                            }
                        }
                    }
                    //递归
                    swap(cate,brand.id);
                    
                    for (let index = 0; index < cate.length; index++) {
                        const element = cate[index];
                        if (element.brandId==node.id){
                         
                            node.children.push(element);
                        }
                    }
                    root.children.push(node);
                }
                 
                return tree; 
            }
        },
        methods: {
          
            handleNodeClick(data) {
                console.log(data);
                let brandId=data.brandId;
                if (data.iscate ){
                    if (this.link){
                        this.$router.push({path:'/shop',query:{brandId:brandId,cateId:data.id}});
                    }
                    else{
                        this.$emit("click",{brandId:brandId,cateId:data.id})
                    }
                }
            }
        },
    }
</script>

<style lang="scss" >

</style>